import { getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import store from "@/store";

interface IFacilityModule {
  _hospitalId: string;
  _hospitalName: string;
}

@Module({
  dynamic: true,
  store,
  name: "facility",
  namespaced: true,
  preserveState: localStorage.getItem("vuex") && JSON.parse(localStorage.getItem("vuex") as string).facility,
})
class FacilityModule extends VuexModule implements IFacilityModule {
  public _hospitalId = "";
  public _hospitalName = "";

  /**
   * 病院ID変更
   * @param id
   */
  @Mutation
  public changeHospitalId(id: string) {
    this._hospitalId = id;
  }
  /**
   * 病院名変更
   * @param id
   */
  @Mutation
  public changeHospitalName(name: string) {
    this._hospitalName = name;
  }

  public get hospitalId() {
    return this._hospitalId || "";
  }
  public get hospitalName() {
    return this._hospitalName || "";
  }
}
export default getModule(FacilityModule);
