
import { Component, Vue } from "vue-property-decorator";
import FacilityService from "@/services/facilities";
import FacilityModule from "@/store/facility";
import AuthModule from "@/store/auth";
import CalendarModule from "@/store/calendar";
import { HospitalData } from "@/@types/management";

@Component
export default class HospitalSelect extends Vue {
  private get me() {
    return AuthModule.me;
  }
  private get hospitalId() {
    return FacilityModule.hospitalId;
  }
  private hospitals: HospitalData[] = [];

  private loading = false;

  private async getHospitals() {
    try {
      this.loading = true;
      const res = await FacilityService.getAll();
      if (this.me?.facility_ids) {
        this.hospitals = res.data.data.filter((hospital: HospitalData) => this.me?.facility_ids.includes(hospital.id));
      } else {
        this.hospitals = [];
      }
      if (this.hospitals.length === 1 && this.me?.role === "operator") {
        this.selectHospital(this.hospitals[0].id, this.hospitals[0].name);
      }
      this.loading = false;
    } catch (error: any) {
      this.loading = false;
      throw new Error(error);
    }
  }

  private selectHospital(id: string, name: string) {
    FacilityModule.changeHospitalId(id);
    FacilityModule.changeHospitalName(name);
    CalendarModule.changeHospitalReset();
    this.$router.push({ name: "Schedule" });
  }

  private async mounted() {
    await this.getHospitals();
  }
}
