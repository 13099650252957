
import { Component } from "vue-property-decorator";
import HospitalSelectForm from "@/components/forms/HospitalSelect.vue";
import NotAuthHeader from "@/components/NotAuthHeader.vue";
import ManagementFooter from "@/components/ManagementFooter.vue";
import CheckCommonLogin from "@/utils/CheckCommonLogin";

@Component({
  components: {
    HospitalSelectForm,
    NotAuthHeader,
    ManagementFooter,
  },
})
export default class HospitalSelect extends CheckCommonLogin {}
