import { Vue, Component } from "vue-property-decorator";
import { Route } from "vue-router";
import AuthModule from "@/store/auth";
import Cognito from "@/plugins/cognito";
import FacilityService from "@/services/facilities";

Component.registerHooks(["beforeRouteEnter"]);

@Component
export default class CheckCommonLogin extends Vue {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected async beforeRouteEnter(to: Route, from: Route, next: any) {
    try {
      const authUser = await Cognito.isAuthenticated();
      if (authUser === null) {
        next((vm: this) => {
          vm.$router.push({ name: "Login" });
        });
      } else {
        const userId = authUser.getUsername();
        const meRes = await FacilityService.getOperatorById(userId);
        const user = meRes.data.data[0];
        if (user) {
          next(async () => {
            AuthModule.changeMe(user);
          });
        } else {
          AuthModule.changeMe(null);
          next((vm: this) => {
            vm.$router.push({ name: "Login" });
          });
        }
      }
    } catch (err) {
      AuthModule.changeMe(null);
      // next(from.path);
      next((vm: this) => {
        vm.$router.push({ name: "Login" });
      });
    }
  }
}
